import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { BoraTreinarComponent } from './components/boraTreinar/boratreinar.component';
import { QuemSouEuComponent } from './components/quemSouEu/quemsoueu.component';
import { BlogComponent } from './components/blog/blog.component';
import { ContatoComponent } from './components/contato/contato.component';
import { BlogArtigoComponent } from './components/blog-artigo/blog-artigo.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: ComponentsComponent },
    { path: 'boratreinar', component: BoraTreinarComponent },
    { path: 'quemsoueu', component: QuemSouEuComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog/artigo/:id/:titulo',  component: BlogArtigoComponent},
    { path: 'contato', component: ContatoComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
