import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class BlogService {
    constructor(private http: HttpClient) { }
    urlBlog: string = "https://www.googleapis.com/blogger/v3/blogs/1546990041719340886/";
    keyBlog: string = "AIzaSyCcq22a6hO1Q15OilHG4OJFlaPewwFYG6o";

    listarArtigos(){
        let url =`${this.urlBlog}posts?key=${this.keyBlog}`;
        return this.http.get(url);
    }

    obterArtigo(id: string){
        let url = `${this.urlBlog}posts/${id}?key=${this.keyBlog}`
        return this.http.get(url);
    }
}


