import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quemsoueu',
  templateUrl: './quemsoueu.component.html',
  styleUrls: ['./quemsoueu.component.scss']
})
export class QuemSouEuComponent implements OnInit {
    simpleSlider = 40;
    doubleSlider = [20, 60];
    state_default: boolean = true;
    focus: any;
    constructor() { }

    ngOnInit() {}
}