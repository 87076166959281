import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boratreinar',
  templateUrl: './boratreinar.component.html',
  styleUrls: ['./boratreinar.component.scss']
})
export class BoraTreinarComponent implements OnInit {
    simpleSlider = 40;
    doubleSlider = [20, 60];
    state_default: boolean = true;
    focus: any;
    constructor() { }

    ngOnInit() {}
}