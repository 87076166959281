import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Artigo } from 'app/model/artigo.model';
import { Imagem } from 'app/model/imagem.model';
import { BlogService } from 'app/services/blog.service';

@Component({
  selector: 'app-blog-artigo',
  templateUrl: './blog-artigo.component.html',
  styleUrls: ['./blog-artigo.component.scss']
})
export class BlogArtigoComponent implements OnInit {    
    simpleSlider = 40;
    doubleSlider = [20, 60];
    state_default: boolean = true;
    focus: any;
    artigo: Artigo;
    constructor(private route: ActivatedRoute, private blogService: BlogService) {
        this.artigo = new Artigo;
     }

    ngOnInit() {
        this.route.params.subscribe((objeto: any) => {            
            this.blogService.obterArtigo(objeto.id).subscribe(
                result => {                                    
                    this.artigo.autor = result["author"]["displayName"];
                    this.artigo.titulo = result["title"];
                    this.artigo.dataPostagem = result["published"];
                    this.artigo.id = result["id"];
                    this.artigo.imagens = this.obterImagens(result["content"]);   
                    this.artigo.paragrafos =  this.obterParagrafos(result["content"]);   
                    console.log(this.artigo.paragrafos);
                }
            );
        })
    }

    obterParagrafos(conteudo: string){
        var parser = new DOMParser();
        var doc = parser.parseFromString(conteudo, 'text/html');
        var listaParagrafos: string[] = [];
        var elementosParagrafo =  doc.getElementsByTagName("p");        
        for (let index = 0; index < elementosParagrafo.length; index++) {
            if(elementosParagrafo[index].textContent != ""){
                listaParagrafos.push(elementosParagrafo[index].textContent);            
            }
        }        
        return listaParagrafos        
    }


    obterImagens(conteudo: string){
        var parser = new DOMParser();
        var doc = parser.parseFromString(conteudo, 'text/html');
        var listaImagens =  doc.getElementsByTagName("img");
        var imagens: Imagem[] = [];        
        for (let index = 0; index < listaImagens.length; index++) {
            imagens.push({url: listaImagens[index].getAttribute("src"),
                          alt: listaImagens[index].getAttribute("alt"),
                          titulo: listaImagens[index].getAttribute("title") });
          }             
        return imagens;
    }
}