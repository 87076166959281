import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from 'app/services/blog.service';
import { ArtigoPrevia } from 'app/model/artigoprevia.model';
import { Imagem } from 'app/model/imagem.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})

export class BlogComponent implements OnInit {
    simpleSlider = 40;
    doubleSlider = [20, 60];
    state_default: boolean = true;
    focus: any;
    listaArtigos: ArtigoPrevia[];

    constructor(private router: Router, private blogService: BlogService) {
      this.listaArtigos = [];
      this.listarArtigos();
    }

    ngOnInit() {}

    abrirArtigo(id: string){
      this.router.navigate(['blog/artigo', id]);
    }

    listarArtigos(){
      this.blogService.listarArtigos().subscribe(
        result => {          
          result['items'].forEach(element => {             
            this.listaArtigos.push({
                id: element["id"],
                titulo: element["title"],
                tituloUrl: this.transformarTituloPraUrl(element["title"]),
                dataPostagem: element["published"],
                autor: element["author"]["displayName"],
                conteudoBreve: this.extrairPreviaArtigo(element["content"]),
                thumbnail: this.tratarThumbnail(element["content"])})            
          });
        }
      );
    }

    tratarThumbnail(stringHtml: string){      
      var parser = new DOMParser();
	    var doc = parser.parseFromString(stringHtml, 'text/html');  
      var url = doc.getElementsByTagName("img")[0]?.getAttribute("src");
      var alt = doc.getElementsByTagName("img")[0]?.getAttribute("alt");
      var titulo = doc.getElementsByTagName("img")[0]?.getAttribute("title");
      
      console.log(doc.getElementsByTagName("img")[0]?.getAttribute("title"));
      console.log(doc.getElementsByTagName("img")[0]);
      const imagem: Imagem = {
        url: url == null ? "/assets/img/artigo_padrao_img.png" : url,
        alt: alt == null ? "imagem padrão" : alt,
        titulo: titulo == null ? "título padrão" : titulo
      }      
      return imagem;
    }

    transformarTituloPraUrl(titulo: string){
      var url = titulo.toLocaleLowerCase().trim();
      url = url.replace(/\s/g, '-');
      url = url.replace(/[,!@#$%¨&*?:;.]/g,"");
      url = url.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      return url;
    }

    extrairPreviaArtigo(stringHtml: string){
      var parser = new DOMParser();
	    var doc = parser.parseFromString(stringHtml, 'text/html');
      var paragrafos =  doc.getElementsByTagName("p");
      var textoParagrafos = "";
      for (let index = 0; index < paragrafos.length; index++) {
        textoParagrafos += paragrafos[index].textContent;        
      }
      return textoParagrafos.slice(0, 180);
    }
}